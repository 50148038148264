import styles from './ShootingStars.module.scss';
import React from 'react';

const NUM_OF_STARS = 20;
const keys = Array.from({ length: NUM_OF_STARS }, (_, i) => i);

export default function ShootingStars() {
  return (
    <div className={styles.stars}>
      {keys.map((key) => (
        <div className={styles.star} key={key} />
      ))}
    </div>
  );
}
